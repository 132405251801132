import storage from "store";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "@/store/mutation-types";
import { getTokenByCode, getUserInfo } from "@/api/auth";
import store from "@/store";

const user = {
  state: {
    access_token: "",
    refresh_token: "",
    permissions: [],
    info: null,
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
  },
  actions: {
    async CodeLogin({ commit }, data) {
      const res = await getTokenByCode({
        ...data,
        redirect_uri: store.getters.redirectUri,
      });
      const accessToken = res.accessToken;
      const refreshToken = res.refreshToken;
      const expireIn = res.expireIn;
      const refreshTokenExpireIn = res.refreshTokenExpireIn;
      const tokenType = res.tokenType;
      storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000);
      storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000);
      commit("SET_ACCESS_TOKEN", accessToken);
      commit("SET_REFRESH_TOKEN", refreshToken);
      return accessToken;
    },
    // 获取用户信息
    async GetInfo({ commit }) {
      const userInfo = await getUserInfo();
      if (!userInfo) {
        commit("SET_INFO", null);
        commit("SET_PERMISSIONS", []);
        storage.remove(ACCESS_TOKEN);
        storage.remove(REFRESH_TOKEN);
        throw "您的账号已被删除";
      }
      commit("SET_INFO", userInfo);
      commit("SET_PERMISSIONS", ["user"]);
      return userInfo;
    },
    // 登出
    async RevokeToken({ commit }) {
      commit("SET_INFO", null);
      commit("SET_PERMISSIONS", []);
      storage.remove(ACCESS_TOKEN);
      storage.remove(REFRESH_TOKEN);
    },
  },
};

export default user;
