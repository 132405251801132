import router from "./router";
import store from "./store";
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { addViewed, setPVAndUV } from "@/api/common";
import { generateUUID } from "ant-design-vue/lib/vc-select/util";

const loginRoutePath = "/login";
const defaultRoutePath = "/";

router.beforeEach(async (to, from, next) => {
  const promises = [];
  await Promise.all(promises);

  if (to.path === loginRoutePath) {
    window.location.href = `${
      process.env.VUE_APP_API_PORTAL_URL
    }/auth/render?redirect_uri=${store.getters.redirectUri}&state=${
      to.query.state || ""
    }`;
    // window.location.href = store.getters.loginPage(from);
    return;
  }
  if (to.path === "/logout") {
    await store.dispatch("RevokeToken");
    window.location.href = `${process.env.VUE_APP_API_PORTAL_URL}/auth/logout?redirect_uri=${store.getters.redirectUri}`;
    // window.location.href = store.getters.loginPage(from);
    return;
  }
  if (to.path === "/authorized") {
    if (!to.query.code) {
      next({ path: defaultRoutePath });
      return;
    }
    try {
      await store.dispatch("CodeLogin", { ...to.query });
      await store.dispatch("GetInfo");
    } catch (e) {
      next({ path: defaultRoutePath });
      return;
    }
    if (to.query.state && storage.get(`history-path-${to.query.state}`)) {
      const historyPath = storage.get(`history-path-${to.query.state}`);
      const historyQuery = storage.get(`history-query-${to.query.state}`);
      next({ path: historyPath, query: historyQuery });
    } else {
      next({ path: defaultRoutePath });
    }
    return;
  }
  const token = storage.get(ACCESS_TOKEN);
  // 目标路由是否需要登录
  const needLogin = (to) => to.path.indexOf("/account") !== -1;
  // 如果token不存在
  if (!token) {
    if (needLogin(to)) {
      const key = generateUUID();
      storage.set(`history-path-${key}`, to.path);
      storage.set(`history-query-${key}`, to.query);
      next({ path: loginRoutePath, query: { state: key } });
    } else {
      next();
    }
    // 如果token存在并且当前用户信息已存在
  } else if (store.getters.userInfo) {
    if (to.path.indexOf("/account") !== -1) {
      window.location.href = `${
        process.env.VUE_APP_ACCOUNT_PREFIX
      }${to.path.replace("/account", "")}`;
      return;
    }
    next();
    // 如果token存在，并且用户信息不存在
  } else {
    // 获取用户信息
    try {
      await store.dispatch("GetInfo");
      if (to.path.indexOf("/account") !== -1) {
        window.location.href = `${
          process.env.VUE_APP_ACCOUNT_PREFIX
        }${to.path.replace("/account", "")}`;
        return;
      }
      next();
    } catch (e) {
      await store.dispatch("RevokeToken");
      if (needLogin(to)) {
        next("/login");
      } else {
        next();
      }
    }
  }
});

router.afterEach((to, from) => {
  if (to.path === "/policy/info") {
    addViewed("news", to.query.id);
  } else if (to.path === "/result/info") {
    addViewed("achv", to.query.id);
  } else if (to.path === "/activity/courseInfo") {
    addViewed("course", to.query.id);
  } else if (to.path === "/activity/activityInfo") {
    addViewed("course", to.query.id);
  }
  let path = to.fullPath;
  setPVAndUV({
    path,
  });
});
