import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";

const getters = {
  access_token: () => storage.get(ACCESS_TOKEN),
  userInfo: (state) => state.user.info,
  isLogin: (state) => !!state.user.info,
  tenant: (state) => state.tenant.tenant,
  settings: (state) => state.settings.settings,
  dict: (state) => state.dict.dict,
  dictText: (state) => (code, value) => {
    const list = state.dict.dict[code];
    const arr = (list || [])
      .filter((e) => e.itemValue === value)
      .map((e) => e.itemText);
    // eslint-disable-next-line no-mixed-operators
    return (arr && arr.length && arr[0]) || "";
  },
  redirectUri: (state) => process.env.VUE_APP_REDIRECT_URI,
};

export default getters;
