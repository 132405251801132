<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      locale: zhCN,
    };
  },
  computed: mapGetters(["userInfo"]),
  mounted() {
    document.title = "鸿联云";
    this.$jsonp("http://localhost:9309/cas/common/login-status", {}).then(
      (res) => {
        if (res.isLogin && !this.userInfo) {
          // this.$router.push("/login");
        }
      }
    );
  },
};
</script>
<style>
#app {
  height: 100%;
}
</style>
