import { PortalRequest as request } from "@/utils/request";

export const getDicts = (pageParams, data) => {
  return request({
    url: "/dict/list",
    method: "post",
    params: pageParams,
    data,
  });
};

export const getTotalDicts = () => {
  return request({
    url: "/dict/total-list",
    method: "post",
  });
};

export const getDictItems = (pageParams, data) => {
  return request({
    url: "/dict/items",
    method: "post",
    params: pageParams,
    data,
  });
};

export const getTotalDictItems = (data) => {
  return request({
    url: "/dict/total-items",
    method: "post",
    data,
  });
};

// 查看全部字典选项
export const getTypeList = (data) => {
  return request({
      url: `/dict/list-options`,
      method: "post",
      data
  });
};