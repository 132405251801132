import { getSettings } from "@/api/common";

export default {
  state: {
    settings: null,
  },
  mutations: {
    SET_SETTINGS(state, settings) {
      state.settings = settings;
    },
  },
  actions: {
    LoadSettings({ commit }) {
      return new Promise((resolve) => {
        getSettings().then((data) => {
          commit("SET_SETTINGS", data);
          resolve(data);
        });
      });
    },
  },
};
