import axios from "axios";
import store from "@/store";
import storage from "store";
import notification from "ant-design-vue/es/notification";
import { VueAxios } from "./axios";
import { ACCESS_TOKEN } from "@/store/mutation-types";

// portal-api
const PortalRequest = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_PORTAL_URL,
  timeout: 50000, // 请求超时时间
});

const errorHandler = (error) => {
  const token = storage.get(ACCESS_TOKEN);
  const response = error.response;
  if (response) {
    if (response.status === 401) {
      if (token) {
        store.dispatch("Logout").then(() => {});
        // eslint-disable-next-line no-use-before-define
        return Promise.reject(error);
      }
    }
    // eslint-disable-next-line no-empty
    if (response.status === 403) {
      console.log(response.status);
    }
    const msg = response.data.msg;
    const error = response.data.error;
    const errorDescription = response.data.error_description;
    if (errorDescription === "Bad credentials") {
      notification.warning({
        message: "错误",
        description: "用户名或密码有误",
      });
    } else if (errorDescription === "User is disabled") {
      notification.warning({
        message: "Forbidden",
        description:
          "Your account has been disabled in JointForce, please  contact customer service team for help: globalsupport@jfh.com.",
      });
    } else if (error === "invalid_token") {
      notification.warning({
        message: "Forbidden",
        description: "Login expired",
      });
    } else if (error === "Internal Server Error") {
      notification.warning({
        message: "提示信息",
        description: msg,
      });
    } else if (errorDescription) {
      notification.warning({
        message: "Forbidden",
        description: errorDescription,
      });
    } else {
      notification.warning({
        message: "提示信息",
        description: msg,
      });
    }
  }
  return Promise.reject(error);
};

const configHandler = (config) => {
  const token = storage.get(ACCESS_TOKEN);
  /*
   * 如果 token 存在
   * 让每个请求携带自定义 token 请根据实际情况自行修改
   */
  if (config.url.startsWith("/common") || config.url.startsWith("/auth")) {
    return config;
  }
  if (token && !config.headers.Authorization) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
};
const responseHandler = (response) => {
  return response.data.data;
};
PortalRequest.interceptors.request.use(configHandler, errorHandler);
PortalRequest.interceptors.response.use(responseHandler, errorHandler);

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, PortalRequest);
  },
};

export default PortalRequest;

export { installer as VueAxios, PortalRequest };
